import { Component, Input, OnInit, Optional, ViewChild } from "@angular/core"
import { select, Store } from "@ngrx/store"
import { Angulartics2 } from "angulartics2"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { AsignaturaWithConfig, GeneradorInstrumento, GeneradorInstrumentos } from "@puntaje/nebulosa/api-services"
import { AuthService } from "@puntaje/shared/core"
import {
    EvaluacionInstancia,
    EvaluacionInstancias,
    EvaluacionUsuario,
    EvaluacionUsuarios,
    GrupoUsuario,
    GrupoUsuarios
} from "@puntaje/puntaje/api-services"
import { selectAsignaturasByEvaluacionTipo, State } from "@puntaje/puntaje/store"
import { Observable, Subscription } from "rxjs"
import { filter } from "rxjs/operators"

@Component({
    templateUrl: "ensayos_breves.component.html",
    selector: "ensayos-breves",
    styleUrls: ["ensayos_breves.component.scss"]
})
export class EnsayosBrevesComponent implements OnInit {
    @Input() tracking: boolean = false
    @Input() evaluacionTipo
    lista_asignaturas: any = []
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild("loadingLayout2", { static: true }) loadingLayout2: LoadingLayoutComponent
    enableResponsive: boolean = false
    ultimasEvaluaciones: any = []
    evaluaciones: any = {}

    sub: Subscription
    asignaturasByEvaluacionTipo$: Observable<{ [evaluacionTipo: string]: AsignaturaWithConfig[] }> = this.store.pipe(
        select(selectAsignaturasByEvaluacionTipo),
        filter(x => !!x)
    )

    constructor(
        protected store: Store<State>,
        private generadorService: GeneradorInstrumentos,
        private gruposService: GrupoUsuarios,
        private evalUsuarioService: EvaluacionUsuarios,
        private evaluacionInstancias: EvaluacionInstancias,
        private authService: AuthService,
        @Optional() public angulartics2: Angulartics2
    ) {}

    ngOnInit() {
        //Esto va a buscar las asignaturas a la base de datos con los id's que le corresponden
        this.sub = this.asignaturasByEvaluacionTipo$.subscribe(asignaturasByEvaluacionTipo => {
            this.lista_asignaturas =
                asignaturasByEvaluacionTipo[this.evaluacionTipo || config.plataforma.evaluacionDefault]
            this.setEnsayosData()
        })
    }

    setEnsayosData() {
        this.generadorService
            .where({ asignatura_plataforma: { plataforma_id: config.plataforma.id }, with_asignatura: true })
            .then((generadores: GeneradorInstrumento[]) => {
                let gen_ids = generadores.map(g => g.id)
                this.gruposService
                    .where({ grupo_usuario: { nombre: "Usuarios UMayor" }, search: 1 })
                    .then((grupos: GrupoUsuario[]) => {
                        let g = grupos[0]
                        this.evalUsuarioService
                            .where({
                                evaluacion_usuario: {
                                    receptor_type: "GrupoUsuario",
                                    receptor_id: g.id,
                                    instrumentos: {
                                        generador_instrumento_id: gen_ids
                                    }
                                },
                                mensual: true,
                                with_instrumento: true
                            })
                            .then((response: EvaluacionUsuario[]) => {
                                response.forEach((eu: EvaluacionUsuario) => {
                                    let a = this.lista_asignaturas.find(
                                        asignatura => asignatura.id == eu.evaluacion.instrumento.asignatura_id
                                    )
                                    if (a) {
                                        this.evaluaciones[a.id] = eu.evaluacion
                                    }
                                })

                                let eval_ids = response.map(e => e.evaluacion_id)
                                this.evaluacionInstancias
                                    .where({
                                        with_instrumento: true,
                                        evaluacion_instancia: {
                                            evaluacion_id: eval_ids,
                                            usuario_id: this.authService.getUserData().id,
                                            oficial: 1
                                        }
                                    })
                                    .then((instancias: EvaluacionInstancia[]) => {
                                        instancias.forEach((instancia: EvaluacionInstancia) => {
                                            let a = this.lista_asignaturas.findIndex(
                                                asignatura =>
                                                    asignatura.id == instancia.evaluacion.instrumento.asignatura_id
                                            )
                                            if (a >= 0 || a !== undefined || a !== null) {
                                                this.ultimasEvaluaciones.push({
                                                    instancia: instancia,
                                                    asignaturaIndex: a
                                                })
                                            }
                                        })
                                        this.loadingLayout.ready()
                                        this.loadingLayout2.ready()
                                    })
                            })
                    })
            })
    }

    trackClickEnsayo(category: string): void {
        if (this.tracking && this.angulartics2) {
            this.angulartics2.eventTrack.next({
                action: "click",
                properties: {
                    category: category,
                    label: "Hacer ensayo"
                }
            })
        }
    }
}
